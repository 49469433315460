<template>
  <div class="about">
    <h3>商务合作</h3>
    <div class="text">邮箱：cdpee@sina.com <span class="span">企业、就业服务机构热线：010-68060651</span></div>
    <h3>客户服务</h3>
    <span>客服邮箱：cjrjcypt@sina.com</span><span  class="span">客服热线：010-68060691</span>
    <!-- <div class="address">地址：北京市东城区王府世纪</div> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
  .about{
    h3{
      padding: 20px 0;
    }
    .span{
      padding-left: 40px;
    }
    .address{
      margin-top:10px;
    }
  }
</style>